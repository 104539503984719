import ApiBooking from "../../../api/ApiBooking";
import validation from "./validation";
const apiBooking = new ApiBooking({});

export default async function createBooking({
    bookingPanel,
    setValids,
    type,
},cb = ()=> {}){
    let data = validation({bookingPanel, setValids, type});
    if(!data) return null;
    const result = await apiBooking.createBooking({...data, type}, (data)=> {cb(data)});
    if(result){
        return true;
    }
    else{
        if(apiBooking.errors)
            setValids(apiBooking.errors.valids);
        else setValids({errors: ['Возникла ошибка']});
        return false;
    }
}