import React, { useRef, useEffect }  from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from '@mui/material/Button';
import BookingRow from '../bookings/__row';

import openBookingPanel from "../booking-panel/actions/openBookingPanel";
import { setDate } from "../../reducers/PageBooking/PageBookingActions";
import store from "../../reducers/store";
import './styles.css';

function useObservePagination({slotTimeRef, isActive, timeId, dispatch, date}) {
    useEffect(() => {
        const item = slotTimeRef.current;
        if (!isActive && item && timeId === 24) {
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const item = entry.target;
                        dispatch(setDate(date));
                        observer.unobserve(item);
                    }
                });
            }, {
                threshold: 0.25,
                trackVisibility: true,
                delay: 100,
            });
            observer.observe(item);
            return () => {
                observer.unobserve(item);
            };
        }
    }, [dispatch, date, isActive, slotTimeRef, timeId]);
}

export default function SlotTime({timeId, timeStart, date, isActive, ...other}){
    const dispatch = useDispatch();
    const slug = `${date}--${timeId}`;
    const slotTimeRef = useRef(null);
    const roles = useSelector((state) => state.profile.roles,
        (oldValue, newValue) => oldValue.length === newValue.length);
    const slotTime = useSelector((state) => (date in state.pageBooking.slotTimes && timeId in state.pageBooking.slotTimes[date])
        ? state.pageBooking.slotTimes[date][timeId] : null);
    const isActiveSlotTime = useSelector((state) => state.bookingPanel.slotTimeId === timeId && state.bookingPanel.date === date);
    const nowTime = (new Date()).getTime();
    const isPrevTimeSlotTime = (slotTime) ? (slotTime.dateStart.getTime() <= nowTime)
        : (date && timeStart && (new Date(`${date} ${timeStart}`)).getTime() <= nowTime) ? true : false;
    const actionAddItem = () => {
        openBookingPanel({
            dispatch,
            ...store.getState().bookingPanel,
            bookingId: null,
            slotTimeId: timeId,
            date,
        });
    }
    useObservePagination({slotTimeRef, isActive, timeId, dispatch, date});
    useEffect(()=>{
        if(isActiveSlotTime){
            let timerId = setTimeout(function(){
                slotTimeRef.current.scrollIntoView({behavior: "smooth", block: "center", inline: "start"});
            }, 500);
            return () =>{
                clearTimeout(timerId);
            }
        }
    }, [isActiveSlotTime]);

    let percentFree = (slotTime && !isPrevTimeSlotTime) ? slotTime.timeFree/slotTime.interval*100 : 0;
   
    const isPrimary = slotTime && (((slotTime.timeId == 28 || slotTime.timeId == 32 || slotTime.timeId  == 36) && !slotTime.isDayOff) || ((slotTime.timeId > 21 && slotTime.timeId < 41) && slotTime.isDayOff)) ? true : false;

    return (<div
        className={
            `grid-booking-content__bookings-row-wrapper booking__time-slot`
            + (!slotTime ? ' load' : '')
            + (isActiveSlotTime ? ' active' : '')
            + (isPrevTimeSlotTime ? ' prev-time' : '')
        }
        ref={slotTimeRef} id={slug}>
        <div className={`booking__time-slot-time-wrapper sticky-booking-content__bookings-row-col-1 ${isPrimary ? 'isPrimary' : ''}`}>
            <div className="booking__time-slot-time">{timeStart}</div> 
        </div>
        <div className="booking__time-slot-group">
            <div className="booking__time-slot-items">
                {slotTime && slotTime.bookings.map((booking, key) =>{
                    return <BookingRow
                        booking={booking}
                        key={key}
                        slotTime={slotTime}
                        isPrevTimeSlotTime={isPrevTimeSlotTime}
                        maxMinutes={slotTime.interval}
                    />
                })}
            </div>
            {(slotTime && slotTime.timeFree >= 2) ? //minimum treshold for consider
            <div className="booking__time-slot-btn">
                <Button
                    variant="outlined"
                    onClick={(roles.includes('admin')) ? actionAddItem : null}
                    color={(slotTime.timeFree > 0) ? "primary" : "error"}
                    sx={{
                        borderRadius: 20,
                        height: 25,
                        textTransform: 'lowercase',
                        background: (percentFree === 100)
                        ? '#a6ebb7'
                        : `linear-gradient(to left,  white ${100-percentFree}%, #a6ebb7 ${percentFree}%)`,
                    }}
                >Свободно: {slotTime.timeFree} мин</Button>
            </div>
            : null }
        </div>

    </div>
    );
}

