import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "./__header";
import Content from "./__content";
import Footer from "./__footer";
import ModalYesNo from "../modal-yes-no/modal-yes-no";
import Loading from "./__loading";

import { loadSlotTime } from "../../reducerApi/Booking";
import { setIsLoadUpdate, clearByRequest, setBookingId, setOrderId } from "../../reducers/BookingPanel/BookingPanelActions";
import createBooking from "./actions/createBooking";
import updateBooking from "./actions/updateBooking";
import cancelBooking from "./actions/cancelBooking";
import { loadDataOrders } from "../../reducerApi";
import "./styles.css";
import MyModal from "../modal/modal";
import Button from "../button/button";
import { Switch } from "@mui/material";
import { formatterBooking } from "../../api/Formatter/Booking";

export default function BookingPanel(){
    const dispatch = useDispatch();
    const [valids, setValids] = useState({});
    const [modalCancel, setModalCancel] = useState(false);
    const [modalRefund, setModalRefund] = useState(false);
    const [modalCancelAndCopy, setModalCancelAndCopy] = useState(false);
    const [actionType, setActionType] = useState(null);
    const [actionStatus, setActionStatus] = useState(null);
    const isSearch = useSelector((state) => state.pageBooking.isSearch);
    const bookingPanel = useSelector((state) => state.bookingPanel);
    const bookingTypes = useSelector((state) => state.list.bookingTypes);
    const type = (bookingPanel.typeId in bookingTypes) ? bookingTypes[bookingPanel.typeId] : null;
    const actionSuccess = async (actionType) =>{
        dispatch(setIsLoadUpdate(true));
        if(bookingPanel.orderId) {
            loadDataOrders({dispatch, ids: [bookingPanel.orderId]})
            dispatch(setOrderId(null))
        }
        if(!isSearch) loadSlotTime({dispatch, date: bookingPanel.date });
        if(actionType !== "cancel-and-copy") dispatch(clearByRequest());
        else dispatch(setBookingId(null));
    }
    const actionError = async (actionType) =>{}
    const actionCreate = async() =>{
        setActionStatus('load');
        setActionType("create");
        let result = await createBooking({ bookingPanel, setValids, type }, (data)=> {
            // 
            if(data?.result) {
                const $result = formatterBooking(data.response.data)
                dispatch(setOrderId($result?.orderId))
            }
        });
        if(result) setActionStatus('success');
        else setActionStatus('error');
    }
    const actionUpdate = async() =>{
        setActionStatus('load');
        setActionType("update");
        let result = await updateBooking({ bookingPanel, setValids, type });
        if(result) setActionStatus('success');
        else setActionStatus('error');
    }
    const actionCancel = async(refund = false) =>{
        setActionStatus('load');
        setActionType("cancel");
        let result = await cancelBooking({ bookingPanel, setValids, type, refund });
        if(result) setActionStatus('success');
        else setActionStatus('error');
    }
    const actionCancelAndCopy = async() =>{
        setActionStatus('load');
        setActionType("cancel-and-copy");
        let result = await cancelBooking({ bookingPanel, setValids, type });
        if(result) setActionStatus('success');
        else setActionStatus('error');
    }
    return <div className="booking-panel">
        {actionStatus ? 
            <Loading 
                type={actionType} 
                status={actionStatus}
                actionSuccess={actionSuccess}
                actionError={actionError}
                close={()=>{
                    setActionStatus(null);
                    setActionType(null);
                }}
            /> 
        : null}
        <Header valids={valids} type={type}/>
        <Content valids={valids} type={type}/>
        <Footer 
            valids={valids}
            type={type}
            actionCreate={actionCreate}
            actionUpdate={actionUpdate}
            actionCancel={()=>setModalCancel(true)}
            actionCancelAndCopy={()=>setModalCancelAndCopy(true)}
        />
        <MyModal
            title="Отменить бронирование"
            isOpenModal={modalCancel}
            closeModal={()=>setModalCancel(false)}
        >
            { type && type?.slug == 'booking' ? 
                <div>
                    <div className="modal-yes-no__footer">
                        <div className="modal-yes-no__footer-left">
                            <Button 
                                color="success"
                                variant="outlined"
                                size="small"
                                onClick={()=>{
                                    setModalRefund(true);
                                    setModalCancel(false);
                                }}
                            >Да</Button>
                        </div>
                        <div className="modal-yes-no__footer-right">
                            <Button 
                                color="error"
                                variant="outlined"
                                size="small"
                                onClick={()=>setModalCancel(false)}
                            >Нет</Button>
                        </div>
                    </div>
                </div>
            :
            <div className="modal-yes-no__footer">
                <div className="modal-yes-no__footer-left">
                    <Button 
                        color="success"
                        variant="outlined"
                        size="small"
                        onClick={()=>{
                            actionCancel();
                            setModalCancel(false);
                        }}
                    >Да</Button>
                </div>
                <div className="modal-yes-no__footer-right">
                    <Button 
                        color="error"
                        variant="outlined"
                        size="small"
                        onClick={()=>setModalCancel(false)}
                    >Нет</Button>
                </div>
            </div>
        }
        </MyModal>
        <MyModal
          title="Начислить средства на депозит при отмене?"
          isOpenModal={modalRefund}
          closeModal={()=>setModalRefund(false)}
        >
             <div className="modal-refund__footer">
                <div className="modal-refund__footer-left">
                    <Button 
                        color="success"
                        variant="outlined"
                        size="small"
                        onClick={()=>{
                            actionCancel(true);
                            setModalRefund(false); // Добавить параметр по возврату средств
                        }}
                    >Да</Button>
                </div>
                <div className="modal-refund__footer-left">
                    <Button 
                        color="error"
                        variant="outlined"
                        size="small"
                        onClick={()=> {
                            actionCancel();
                            setModalRefund(false)
                        }}
                    >Нет</Button>
                </div>
                <div className="modal-refund__footer-right">
                    <Button 
                        color="error"
                        variant="outlined"
                        size="small"
                        onClick={()=>setModalRefund(false)}
                    >Закрыть</Button>
                </div>
            </div>
        </MyModal>
        <ModalYesNo
            title="Отменить бронирование и копировать"
            isOpenModal={modalCancelAndCopy}
            closeModal={()=>setModalCancelAndCopy(false)}
            actionBtnYes={()=>{
                actionCancelAndCopy();
                setModalCancelAndCopy(false);
            }}
            actionBtnNo={()=>setModalCancelAndCopy(false)}
        />
    </div>
}